import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import RootStackParamList from "../Types/RootStackParamList";
import theme from "../theme";
import HazardAssessment from "../Views/HazardAssessment";
import TabNavigator from "./TabNavigator";
import Login from "../Views/Login";
import Settings from "../Views/Settings";
import HazardCreation from "../Views/HazardCreation";

// StackNavigator: main navigation for app

const StackNavigator = () => {
  const Stack = createStackNavigator<RootStackParamList>();
  const customHeader: object = {
    title: "Unity Health & Safety",
    headerStyle: { backgroundColor: theme.colors.primary },
    headerTitleStyle: { color: "white" },
    headerTintColor: "white",
  };
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen
          name="Home"
          component={TabNavigator}
          options={customHeader}
        />
        <Stack.Screen
          name="Create"
          component={HazardCreation}
          options={customHeader}
        />
        <Stack.Screen
          name="Hazard"
          component={HazardAssessment}
          // send hazardId to hazardAssessment screen
          initialParams={{ hazardId: 0, source: "default" }}
          options={customHeader}
        />
        <Stack.Screen name="Login" component={Login} options={customHeader} />
        <Stack.Screen
          name="Settings"
          component={Settings}
          options={customHeader}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default StackNavigator;
