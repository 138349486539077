import React from "react";
import { Provider as PaperProvider } from "react-native-paper";
import theme from "./src/theme";
import StackNavigator from "./src/Components/StackNavigator";

// App : Main app component

const App = () => {
  return (
    <PaperProvider theme={theme}>
      <StackNavigator />
    </PaperProvider>
  );
};

export default App;
