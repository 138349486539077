import { Text, Title, List, IconButton, Button } from "react-native-paper";
import { View, StyleSheet, ScrollView, Platform } from "react-native";
import theme from "../theme";
import RootStackParamList from "../Types/RootStackParamList";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import axios from "../Services/customAxios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";

// set current screen
type ScreenProps = NativeStackScreenProps<RootStackParamList, "Home">;

// HazardAssessmentIndex: list of current hazard assessment drafts

const HazardAssessmentIndex = ({ navigation }: ScreenProps) => {
  // store list of hazardAssessments
  const [hazardAssessmentList, setHazardAssessmentList] = useState([]);
  // track if screen is focused
  const isFocused = useIsFocused();

  // check token
  const getToken = async () => {
    await AsyncStorage.getItem("accessToken").then((response) =>
      response === null ? navigation.replace("Login") : axiosCall(response)
    );
  };
  // get data
  const axiosCall = async (token) => {
    const headers =
      Platform.OS !== "web"
        ? {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "no-cache",
          }
        : { "Content-Type": "application/x-www-form-urlencoded" };

    // get current hazardAssessment drafts
    axios
      .get("/hazardAssessment/list?token=" + token, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? setHazardAssessmentList(response.data.reply)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("List: " + error.response?.data));
  };

  // recall when screen is focused
  useEffect(() => {
    if (isFocused) {
      getToken();
    }
  }, [isFocused]);

  return (
    <>
      <ScrollView>
        <View>
          <View style={styles.container}>
            <Title>Field Hazard Assessments</Title>
          </View>
          <Text style={styles.heading}>
            {hazardAssessmentList?.length !== 0 ? "Current Drafts" : ""}
          </Text>
          {hazardAssessmentList?.length !== 0 ? (
            hazardAssessmentList?.map((ha) => (
              <List.Item
                style={[styles.list, { borderColor: theme.colors.primary }]}
                key={ha.hazardAssessmentId}
                title={ha.jobTitle}
                description={"Start Date: " + ha.jobStartDate.substring(0, 10)}
                left={(props) => (
                  <List.Icon {...props} icon="clipboard-text-outline" />
                )}
                right={(props) => (
                  <IconButton
                    icon="pencil"
                    color={theme.colors.primary}
                    style={{ top: 3 }}
                    onPress={() =>
                      navigation.navigate("Hazard", {
                        hazardId: ha.hazardAssessmentId,
                        source: "listings",
                      })
                    }
                  />
                )}
              />
            ))
          ) : (
            <View>
              <IconButton
                style={{ alignSelf: "center" }}
                size={30}
                icon="check-circle"
                color={"green"}
              ></IconButton>
              <Text
                style={[
                  styles.heading,
                  {
                    alignSelf: "center",
                    textAlign: "center",
                    paddingTop: 0,
                    marginBottom: 60,
                  },
                ]}
              >
                No Pending Assessments
              </Text>
            </View>
          )}
          <Button
            style={[styles.button, { marginBottom: 20 }]}
            mode="contained"
            icon="plus"
            onPress={() => navigation.navigate("Create")}
          >
            New Assessment
          </Button>
        </View>
      </ScrollView>
    </>
  );
};

export default HazardAssessmentIndex;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    padding: 30,
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
  },
  list: {
    alignSelf: "center",
    width: "80%",
    backgroundColor: "#e0e0e0",
    borderWidth: 1,
    borderRadius: 8,
    marginBottom: 10,
  },
  heading: {
    alignSelf: "flex-start",
    paddingTop: 15,
    fontSize: 16,
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
  },
  button: {
    width: "60%",
    borderRadius: 8,
    alignSelf: "center",
    marginTop: 20,
  },
});
