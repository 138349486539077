import { Picker } from "@react-native-picker/picker";
import { StyleSheet, View, Platform } from "react-native";
import { Button } from "react-native-paper";
import { useState, Dispatch, useEffect } from "react";
import KeyValuePair from "../Types/KeyValuePair";

interface CustomPickerProps {
  // picker prompt / title
  prompt: string;
  // picker options
  dataSet: KeyValuePair[];
  // currently selected value
  selection: number;
  // selection setter
  setSelection: Dispatch<React.SetStateAction<number>>;
  // update tolerance
  setTolerance?: Dispatch<React.SetStateAction<number>>;
  // has form been submitted?
  submitted: boolean;
}

// CustomPicker: used for the single selection pickers on HazardAssessment page

const CustomPicker: React.FC<CustomPickerProps> = (props) => {
  const { prompt, dataSet, selection, setSelection, setTolerance, submitted } =
    props;
  // visibility of first dropdown for iOS
  const [toggle, setToggle] = useState<boolean>(false);

  let title = "";
  // title property differs based on dataset
  switch (prompt) {
    case "Select Job":
      title = "title";
      break;
    case "Site":
      title = "siteName";
      break;
    case "Task Category":
      title = "taskCategoryName";
      break;
  }

  useEffect(() => {
    if (prompt === "Select Job" && dataSet.length !== 0) {
      if (selection !== null) {
        let target = dataSet.find(
          (d) => d.id === parseInt(selection.toString())
        );
        setTolerance(target["tolerance"]);
      } else {
        setTolerance(null);
      }
    }
  }, [selection]);

  return (
    /* Platform Specific Styling and Functionality */
    <View
      style={
        selection === null && submitted
          ? [styles.pickerView, { borderColor: "red" }]
          : styles.pickerView
      }
    >
      {Platform.OS === "ios" ? (
        <View style={styles.buttonContainer}>
          <Button
            mode="contained"
            style={styles.button}
            onPress={() => setToggle(!toggle)}
          >
            {toggle
              ? "Close"
              : selection === null
              ? "Open"
              : dataSet.length > 0
              ? dataSet.find((d) => d.id === selection)[title]
              : ""}
          </Button>
        </View>
      ) : (
        <></>
      )}
      {toggle || Platform.OS !== "ios" ? (
        <>
          <Picker
            prompt={prompt}
            style={styles.picker}
            onValueChange={(val) => setSelection(val)}
            selectedValue={selection}
          >
            <Picker.Item label="Select..." value={null} />
            {dataSet?.map((item) => (
              <Picker.Item label={item[title]} value={item.id} key={item.id} />
            ))}
          </Picker>
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

export default CustomPicker;

const styles = StyleSheet.create({
  picker: {
    width: "100%",
    padding: 20,
  },
  pickerView: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    backgroundColor: "#f8f9fa",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 3,
    marginTop: 15,
    padding: 5,
    paddingTop: Platform.OS !== "web" ? 0 : 5,
  },
  buttonContainer: {
    width: "100%",
  },
  button: {
    marginTop: 5,
  },
});
