import React, { Dispatch, useEffect, useState, useContext } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { TextInput, Text, IconButton } from "react-native-paper";
import theme from "../theme";
import axios from "../Services/customAxios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Risk from "../Types/Risk";

interface RiskProps {
  // risk tolerance value
  tolerance: number;
  // name of risk
  riskName: string;
  // taskId of associated task
  taskIndex: number;
  // riskId of risk
  riskIndex: number;
  // track the severity of each risk ([taskIndex][riskIndex])
  severityList: number[][];
  // severityList setter
  setSeverityList: Dispatch<React.SetStateAction<number[][]>>;
  // track the probability of each risk ([taskIndex][riskIndex])
  probabilityList: number[][];
  // probabilityList setter
  setProbabilityList: Dispatch<React.SetStateAction<number[][]>>;
  // residual risk
  residual: number;
  // hazard assessment id
  hazardId: number;
  // risk delete ids
  riskDeleteIds: object[];
  // riskList
  riskList: Risk[][];
  // setRiskList
  setRiskList: Dispatch<React.SetStateAction<Risk[][]>>;
}

// RiskDisplay : component that displays risk information (residual, severity, probability) for each risk

const RiskDisplay: React.FC<RiskProps> = (props) => {
  const {
    tolerance,
    riskName,
    taskIndex,
    riskIndex,
    severityList,
    setSeverityList,
    probabilityList,
    setProbabilityList,
    residual,
    hazardId,
    riskDeleteIds,
    riskList,
    setRiskList,
  } = props;

  const [sevValue, setSevValue] = useState(
    severityList[taskIndex][riskIndex].toString()
  );
  const [probValue, setProbValue] = useState(
    probabilityList[taskIndex][riskIndex].toString()
  );
  const [resValue, setResValue] = useState(residual);

  useEffect(() => {
    setResValue(residual);
  }, [residual]);

  // calculate difference
  const difference: number = resValue !== undefined ? resValue - tolerance : 0;

  const headers =
    Platform.OS !== "web"
      ? {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache",
        }
      : { "Content-Type": "application/x-www-form-urlencoded" };

  // update residual
  const updateResidual = (res: number) => {
    let riskListArray = riskList.slice();
    riskListArray[taskIndex].map((r) =>
      r.id === riskIndex ? (r.residual = res) : ""
    );
    setRiskList(riskListArray);
    setResValue(res);
  };

  // update severityList
  const handleSeverityUpdate = async (val) => {
    let token = await AsyncStorage.getItem("accessToken");

    setSevValue(val);

    if (
      val === "" ||
      val.indexOf("-") > -1 ||
      val.indexOf(",") > -1 ||
      val.indexOf(".") > -1 ||
      val.indexOf(" ") > -1
    ) {
      val = 0;
    }

    let severityListCopy = severityList;
    severityListCopy[taskIndex][riskIndex] = val;
    setSeverityList(severityListCopy);

    let deleteId = riskDeleteIds.find(
      (r) => r["riskId"] === riskIndex && r["taskId"] === taskIndex
    )["deleteId"];

    let encodedStr = new URLSearchParams({
      hazardAssessmentId: hazardId.toString(),
      updatedRisks:
        "[{riskId:" +
        riskIndex +
        ",taskId:" +
        taskIndex +
        ",severity:" +
        val +
        ",probability:" +
        probabilityList[taskIndex][riskIndex] +
        ",hazardRiskAssessmentId:" +
        deleteId +
        "}]",
      token: token,
    }).toString();

    axios
      .post("/hazardAssessment/updateRisks", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? response.data.reply.success.length > 0
            ? updateResidual(response.data.reply?.success[0].residual)
            : ""
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Update Risks: " + error));
  };

  // update probabilityList
  const handleProbabilityUpdate = async (val) => {
    let token = await AsyncStorage.getItem("accessToken");

    setProbValue(val);

    if (
      val === "" ||
      val.indexOf("-") > -1 ||
      val.indexOf(",") > -1 ||
      val.indexOf(".") > -1 ||
      val.indexOf(" ") > -1
    ) {
      val = 0;
    }

    let probabilityListCopy = probabilityList;
    probabilityListCopy[taskIndex][riskIndex] = val;
    setProbabilityList(probabilityListCopy);

    let deleteId = riskDeleteIds.find(
      (r) => r["riskId"] === riskIndex && r["taskId"] === taskIndex
    )["deleteId"];

    let encodedStr = new URLSearchParams({
      hazardAssessmentId: hazardId.toString(),
      updatedRisks:
        "[{riskId:" +
        riskIndex +
        ",taskId:" +
        taskIndex +
        ",severity:" +
        severityList[taskIndex][riskIndex] +
        ",probability:" +
        val +
        ",hazardRiskAssessmentId:" +
        deleteId +
        "}]",
      token: token,
    }).toString();

    axios
      .post("/hazardAssessment/updateRisks", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? response.data.reply.success.length > 0
            ? updateResidual(response.data.reply.success[0].residual)
            : ""
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Update Risks: " + error));
  };

  return (
    <View>
      <Text style={styles.heading}>{riskName}</Text>
      <View style={styles.textInputRow}>
        <TextInput
          keyboardType="numeric"
          label="Severity"
          mode="outlined"
          value={sevValue}
          style={styles.accordionTextInput}
          onChangeText={(val) => handleSeverityUpdate(val)}
          onBlur={() =>
            sevValue === "" ||
            sevValue.indexOf("-") > -1 ||
            sevValue.indexOf(",") > -1 ||
            sevValue.indexOf(".") > -1 ||
            sevValue.indexOf(" ") > -1
              ? setSevValue("0")
              : ""
          }
        ></TextInput>
        <TextInput
          keyboardType="numeric"
          label="Probability"
          mode="outlined"
          value={probValue}
          style={styles.accordionTextInput}
          onChangeText={(val) => handleProbabilityUpdate(val)}
          onBlur={() =>
            probValue === "" ||
            probValue.indexOf("-") > -1 ||
            probValue.indexOf(",") > -1 ||
            probValue.indexOf(".") > -1 ||
            probValue.indexOf(" ") > -1
              ? setProbValue("0")
              : ""
          }
        ></TextInput>
      </View>
      <View style={styles.iconBox}>
        <Text style={styles.heading}>
          Residual : {resValue !== undefined ? resValue : 0} (
          {(difference >= 0 ? "+" : "") + difference})
        </Text>
        <IconButton
          style={styles.icon}
          size={30}
          icon={resValue > tolerance ? "alert-circle" : "check-circle"}
          color={resValue > tolerance ? theme.colors.error : "green"}
        ></IconButton>
      </View>
    </View>
  );
};

export default RiskDisplay;

const styles = StyleSheet.create({
  accordionTextInput: {
    width: "40%",
    marginTop: 10,
  },
  heading: {
    alignSelf: "center",
    paddingTop: 15,
    fontSize: 16,
    width: "80%",
    textAlign: "center",
  },
  textInputRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  icon: {
    alignSelf: "center",
  },
  iconBox: {
    flex: 1,
    flexDirection: "column",
  },
});
