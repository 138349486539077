import React from "react";
import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs";
import HazardAssessmentIndex from "../Views/HazardAssessmentIndex";
import theme from "../theme";
import Settings from "../Views/Settings";

const Tab = createMaterialBottomTabNavigator();

// TabNavigator: bottom tab navigation, applied in stack navigator

const TabNavigator = () => {
  return (
    <Tab.Navigator
      initialRouteName="Listings"
      barStyle={{ backgroundColor: theme.colors.primary }}
    >
      <Tab.Screen
        name="Listings"
        component={HazardAssessmentIndex}
        options={{
          tabBarIcon: "alert",
          title: "Assessments",
        }}
      />
      <Tab.Screen
        name="Settings"
        component={Settings}
        options={{
          tabBarIcon: "cog",
        }}
      />
    </Tab.Navigator>
  );
};

export default TabNavigator;
