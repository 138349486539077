import { useEffect, useState } from "react";
import { View, StyleSheet, Platform } from "react-native";
import {
  Title,
  TextInput,
  Button,
  Modal,
  Portal,
  Text,
} from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import DismissKeyboard from "../Components/DismissKeyboard";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "../Services/customAxios";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import RootStackParamList from "../Types/RootStackParamList";

// set current screen
type ScreenProps = NativeStackScreenProps<RootStackParamList, "Login">;

// Login: Login form component/page

const Login = ({ navigation }: ScreenProps) => {
  const rootDomainOptions = [
    { id: 0, label: ".compliancescience.ca" },
    { id: 1, label: ".compliancescience.co" },
  ];

  // get domain on initial render
  useEffect(() => {
    getDomain();
  }, []);

  const getDomain = async () => {
    // get domain from local storage
    let sub: string | null = await AsyncStorage.getItem("subdomain");
    let root: string | null = await AsyncStorage.getItem("rootDomain");
    // if there is no domain in localstorage, set modal to visible
    if (sub === null || root === null) {
      setVisible(true);
    }

    if (root !== null) {
      let rootId = rootDomainOptions?.find((o) => o.label === root).id;
      if (rootId !== undefined) {
        setRootDomain(rootId);
      }
    }

    if (sub !== null) {
      // set domainInput
      setSubdomain(sub);
    }
  };

  // visibility of domain modal
  const [visible, setVisible] = useState<boolean>(false);
  // current text input for subdomain
  const [subdomain, setSubdomain] = useState<string>("");
  // selected root domain
  const [rootDomain, setRootDomain] = useState(0);
  // current text input for username
  const [username, setUsername] = useState<string>("");
  // current text input for password
  const [password, setPassword] = useState<string>("");
  // text for error message if there is an error
  const [errorMessage, setErrorMessage] = useState<string>("");
  // ios picker toggle
  const [pickerToggle, setPickerToggle] = useState(false);

  const hideModal = () => {
    setVisible(false);
  };
  const showModal = () => {
    setVisible(true);
  };
  // update local storage with domain url
  const handleURLSubmit = async () => {
    setVisible(false);

    let root = rootDomainOptions?.find((o) => o.id === rootDomain)["label"];

    await AsyncStorage.setItem("subdomain", subdomain);
    await AsyncStorage.setItem("rootDomain", root);
  };

  const handleLogin = () => {
    // on success
    const successfulLogin = async (response) => {
      // if there is an error
      if (response.data.error !== undefined) {
        setErrorMessage(response.data.error);
      }
      // update localStorage and navigate
      else {
        await AsyncStorage.setItem("accessToken", response.data.user.token);
        await AsyncStorage.setItem("userId", response.data.user.id.toString());
        navigation.replace("Home");
      }
    };

    let root = rootDomainOptions?.find((o) => o.id === rootDomain)["label"];

    // check for empty fields
    if (username === "" || password === "") {
      setErrorMessage("Please populate both fields.");
      // check if domainURL is valid
    } else {
      // build data for post
      let encodedString =
        "username=" +
        encodeURIComponent(username) +
        "&password=" +
        encodeURIComponent(password) +
        "&isApi=true";
      // make login call
      axios
        .post("/login/process", encodedString, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) =>
          response.status === 200
            ? successfulLogin(response)
            : console.log("Issue with response data")
        )
        .catch((error) => setErrorMessage("Invalid domain URL."));
    }
  };

  const component = (
    <View style={styles.container}>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={styles.modal}
        >
          <Title style={{ marginBottom: 20 }}>Enter Domain URL</Title>
          <View style={styles.textInputRow}>
            <TextInput
              mode="outlined"
              value="https://"
              disabled
              style={{ width: "35%" }}
            />
            <TextInput
              label="Subdomain"
              mode="outlined"
              value={subdomain}
              onChangeText={(val) => setSubdomain(val)}
              style={{ width: "60%" }}
            />
          </View>
          <View style={styles.pickerView}>
            {Platform.OS === "ios" ? (
              <View style={styles.buttonContainer}>
                <Button
                  mode="contained"
                  style={{ marginTop: 5 }}
                  onPress={() => setPickerToggle(!pickerToggle)}
                >
                  {pickerToggle
                    ? "Close"
                    : rootDomain === null
                    ? "Open"
                    : rootDomainOptions.length > 0
                    ? rootDomainOptions.find((o) => o.id === rootDomain).label
                    : ""}
                </Button>
              </View>
            ) : (
              <></>
            )}
            {pickerToggle || Platform.OS !== "ios" ? (
              <Picker
                prompt={"Root Domain"}
                style={styles.picker}
                onValueChange={(val) => setRootDomain(val)}
                selectedValue={rootDomain}
                itemStyle={{ fontSize: 18 }}
              >
                {rootDomainOptions?.map((option) => {
                  return (
                    <Picker.Item
                      label={option.label}
                      value={option.id}
                      key={option.id}
                    />
                  );
                })}
              </Picker>
            ) : (
              <></>
            )}
          </View>

          <Button
            mode="contained"
            style={styles.button}
            onPress={handleURLSubmit}
          >
            Submit
          </Button>
        </Modal>
      </Portal>
      <Title> Login </Title>
      {errorMessage !== "" ? (
        <Text style={styles.error}> {errorMessage} </Text>
      ) : (
        <Text />
      )}
      <TextInput
        style={styles.textInput}
        label="Username"
        mode="outlined"
        onChangeText={(val) => setUsername(val)}
      />
      <TextInput
        secureTextEntry={true}
        style={styles.textInput}
        label="Password"
        mode="outlined"
        onChangeText={(val) => setPassword(val)}
      />
      <Button
        mode="contained"
        icon="pencil"
        style={styles.button}
        onPress={showModal}
      >
        Change Domain URL
      </Button>
      <Button
        style={styles.button}
        icon="login"
        mode="contained"
        onPress={handleLogin}
      >
        Submit
      </Button>
    </View>
  );

  return Platform.OS !== "web" ? (
    <>
      <DismissKeyboard>{component}</DismissKeyboard>
    </>
  ) : (
    <> {component} </>
  );
};

export default Login;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  textInput: {
    width: "80%",
    marginTop: 20,
  },
  button: {
    marginTop: 20,
  },
  buttonContainer: {
    width: "100%",
  },
  modal: {
    backgroundColor: "white",
    width: "80%",
    height: "60%",
    alignSelf: "center",
    borderRadius: 5,
    alignItems: "center",
  },
  error: {
    color: "red",
    marginTop: 10,
  },
  picker: {
    width: "100%",
  },
  pickerView: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    backgroundColor: "#f8f9fa",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 3,
    marginTop: 15,
    padding: 5,
    paddingTop: Platform.OS !== "web" ? 0 : 5,
  },
  textInputRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%",
  },
});
