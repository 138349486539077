import React, { useEffect, useState } from "react";
import { StyleSheet, View, ScrollView, Platform } from "react-native";
import DismissKeyboard from "../Components/DismissKeyboard";
import { Button, Title, Text } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import RootStackParamList from "../Types/RootStackParamList";
import CustomPicker from "../Components/CustomPicker";
import axios from "../Services/customAxios";
import AsyncStorage from "@react-native-async-storage/async-storage";

// set current screen
type ScreenProps = NativeStackScreenProps<RootStackParamList, "Create">;

// HazardCreation: component/screen for hazardAssessment creation

const HazardAssessment = ({ navigation, route }: ScreenProps) => {
  // id of selected job
  const [jobSelection, setJobSelection] = useState<number | null>(null);
  // id of selected site
  const [siteSelection, setSiteSelection] = useState<number | null>(null);
  // id of selected taskCategory
  const [taskCategorySelection, setTaskCategorySelection] = useState<
    number | null
  >(null);
  const [tolerance, setTolerance] = useState<number | null>(null);
  const [submitted, setSubmitted] = useState(false);
  // job data for picker
  const [activeJobs, setActiveJobs] = useState([]);
  // site data for picker
  const [sites, setSites] = useState([]);
  // task category data for picker
  const [taskCategories, setTaskCategories] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const headers =
    Platform.OS !== "web"
      ? {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache",
        }
      : { "Content-Type": "application/x-www-form-urlencoded" };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (
      jobSelection !== null &&
      siteSelection !== null &&
      taskCategorySelection !== null
    ) {
      // create hazard assessment

      let token = await AsyncStorage.getItem("accessToken");

      let encodedStr = new URLSearchParams({
        jobId: jobSelection.toString(),
        site: siteSelection.toString(),
        taskCategoryId: taskCategorySelection.toString(),
        taskIds: "[]",
        controls: "[]",
        risks: "[]",
        token: token,
      }).toString();

      axios
        .post("/hazardAssessment/create", encodedStr, {
          headers: headers,
        })
        .then((response) =>
          response.status === 200
            ? navigation.replace("Hazard", {
                hazardId: response.data.reply.success.hazardAssessmentId,
                source: "creation",
              })
            : console.log("Issue with response data")
        )
        .catch((error) => console.warn("Post: " + error));
    }
  };

  const getData = async () => {
    let token = await AsyncStorage.getItem("accessToken");
    // get jobs
    axios
      .get("/job/get?token=" + token, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? setActiveJobs(response.data.reply)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Job: " + error.response?.data));
    // get sites
    axios
      .get("/sites/get?token=" + token, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? setSites(response.data.reply)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Sites: " + error.response?.data));

    // get task categories
    axios
      .get("/taskCategory/getTaskCategory?isActive=true&token=" + token, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? setTaskCategories(response.data.reply)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Task Category: " + error.response?.data));
  };

  return (
    <>
      <DismissKeyboard>
        <ScrollView style={styles.scrollView}>
          <View style={styles.container}>
            <Title>New Field Hazard Assessment</Title>
            {/* Job Title */}
            <View style={styles.textRow}>
              <Text style={[styles.heading]}>Select Job *</Text>
              <Text
                style={[styles.heading, { color: "red", textAlign: "right" }]}
              >
                {jobSelection === null && submitted ? "Required" : ""}
              </Text>
            </View>
            <CustomPicker
              prompt="Select Job"
              dataSet={activeJobs}
              selection={jobSelection}
              setSelection={setJobSelection}
              setTolerance={setTolerance}
              submitted={submitted}
            />
            {/* Site */}
            <View style={styles.textRow}>
              <Text style={styles.heading}> Site * </Text>
              <Text
                style={[styles.heading, { color: "red", textAlign: "right" }]}
              >
                {siteSelection === null && submitted ? "Required" : ""}
              </Text>
            </View>
            <CustomPicker
              prompt="Site"
              dataSet={sites}
              selection={siteSelection}
              setSelection={setSiteSelection}
              submitted={submitted}
            />
            {/* Task Category */}
            <View style={styles.textRow}>
              <Text style={styles.heading}> Task Category * </Text>
              <Text
                style={[styles.heading, { color: "red", textAlign: "right" }]}
              >
                {taskCategorySelection === null && submitted ? "Required" : ""}
              </Text>
            </View>
            <CustomPicker
              prompt="Task Category"
              dataSet={taskCategories}
              selection={taskCategorySelection}
              setSelection={setTaskCategorySelection}
              submitted={submitted}
            />
            <Button
              icon="arrow-right-bold"
              mode="contained"
              style={{ marginTop: 20 }}
              onPress={handleSubmit}
            >
              Next
            </Button>
          </View>
        </ScrollView>
      </DismissKeyboard>
    </>
  );
};

export default HazardAssessment;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    padding: 30,
  },
  heading: {
    paddingTop: 15,
    fontSize: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
  },
  textRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%",
  },
  scrollView: {
    backgroundColor: "white",
  },
});
