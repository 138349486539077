import { TouchableWithoutFeedback, Keyboard } from "react-native";

// DismissKeyboard: wrap component so outside area can be tapped to exit keyboard

const DismissKeyboard = ({ children }: any) => {
  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      {children}
    </TouchableWithoutFeedback>
  );
};

export default DismissKeyboard;
