import React, { useState, Dispatch, useEffect, useContext } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { Button, Chip } from "react-native-paper";
import KeyValuePair from "../Types/KeyValuePair";
import axios from "../Services/customAxios";
import Risk from "../Types/Risk";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface PickerProps {
  // picker prompt / title
  prompt: string;
  // list of currently selected data
  dataList: KeyValuePair[];
  // dataList setter
  setDataList: Dispatch<React.SetStateAction<KeyValuePair[]>>;
  // complete list of options
  dataSet: KeyValuePair[];
  // target taskIndex for risk multipicker
  taskIndex?: number;
  // hazard assessment ID
  hazardId: number;
  // task delete IDs
  taskDeleteIds?: object[];
  // set task delete IDS
  setTaskDeleteIds?: Dispatch<React.SetStateAction<object[]>>;
  // control deleteIds
  controlDeleteIds: object[];
  // set control deleteIDs
  setControlDeleteIds: Dispatch<React.SetStateAction<object[]>>;
  // risk delete Ids
  riskDeleteIds?: object[];
  // setRiskDeleteIds
  setRiskDeleteIds?: Dispatch<React.SetStateAction<object[]>>;
  // list of severity values for risks
  severityList?: number[][];
  // list of probability values for risks
  probabilityList?: number[][];
  // list of current risk/control associations
  riskControls?: object[];
  // setter for riskControls
  setRiskControls?: Dispatch<React.SetStateAction<object[]>>;
  // list of currently selectedcontrols
  controls?: KeyValuePair[];
  // setter for controls
  setControls?: Dispatch<React.SetStateAction<KeyValuePair[]>>;
  // list of currently selected risks
  riskList: Risk[][];
  // setter for riskList
  setRiskList: Dispatch<React.SetStateAction<Risk[][]>>;
}

// Multipicker: used for multiselection pickers on HazardAssessment page

const MultiPicker: React.FC<PickerProps> = (props) => {
  const {
    prompt,
    setDataList,
    dataList,
    taskIndex,
    dataSet,
    hazardId,
    severityList,
    probabilityList,
    riskControls,
    setRiskControls,
    controls,
    setControls,
    riskList,
    setRiskList,
    riskDeleteIds,
    setRiskDeleteIds,
    controlDeleteIds,
    setControlDeleteIds,
    taskDeleteIds,
    setTaskDeleteIds,
  } = props;

  // keep original array for comparing to current state
  const defaultArray: KeyValuePair[] = dataSet;
  // current selection from picker
  const [selection, setSelection] = useState<number | null>(null);
  // array of currently selected options (appear as chips)
  const [chipArray, setChipArray] = useState<number[]>(
    dataList !== undefined && dataList.length > 0
      ? dataList.map((data) => data.id)
      : []
  );
  // remaining options in picker (default subtract selected)
  const [optionsArray, setOptionsArray] = useState<KeyValuePair[]>(
    dataList !== undefined && dataList.length > 0
      ? defaultArray.filter((obj) => !chipArray.includes(obj.id))
      : defaultArray
  );

  useEffect(() => {
    setChipArray(dataList?.length > 0 ? dataList.map((data) => data.id) : []);
    setOptionsArray(
      dataList?.length > 0
        ? defaultArray.filter((obj) => !chipArray.includes(obj.id))
        : defaultArray
    );
  }, [dataList, defaultArray]);

  // toggle visibility of dropdown on iOS
  const [toggle, setToggle] = useState<boolean>(false);
  const handlePress = () => {
    setToggle(!toggle);
  };

  const headers =
    Platform.OS !== "web"
      ? {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache",
        }
      : { "Content-Type": "application/x-www-form-urlencoded" };

  const onAttachTasks = (response) => {
    // set taskDeleteIds
    let taskDeleteArray = taskDeleteIds;
    for (let i = 0; i < response.length; i++) {
      taskDeleteArray.push({
        taskId: response[i]["task"]["id"],
        deleteId: response[i]["id"],
      });
    }
    setTaskDeleteIds(taskDeleteArray);
  };

  const attachTasks = async (val) => {
    let token = await AsyncStorage.getItem("accessToken");

    let encodedStr = new URLSearchParams({
      taskIds: "[" + val.toString() + "]",
      hazardAssessmentId: hazardId.toString(),
      token: token,
    }).toString();

    axios
      .post("/hazardAssessment/attachTasks", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? onAttachTasks(response.data.reply.success)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Attach Tasks: " + error.response.data));
  };

  const onAttachControls = (response) => {
    // set  controlDeleteIds
    let controlDeleteArray = controlDeleteIds;
    for (let i = 0; i < response["hazardControls"].length; i++) {
      controlDeleteArray.push({
        controlId: response["hazardControls"][i]["control"]["id"],
        deleteId: response["hazardControls"][i]["id"],
      });
    }
    setControlDeleteIds(controlDeleteArray);

    // update riskList
    let riskListArray = riskList.slice();
    for (let i = 0; i < response["hazardRisks"].length; i++) {
      let riskId = response["hazardRisks"][i]["risk"]["id"];
      let taskId = response["hazardRisks"][i]["task"]["id"];
      let residual = response["hazardRisks"][i]["residual"];
      riskListArray[taskId].find((r) => r.id === riskId).residual = residual;
    }
    setRiskList(riskListArray);
  };

  const attachControls = async (val) => {
    let token = await AsyncStorage.getItem("accessToken");

    let encodedStr = new URLSearchParams({
      controlIds: "[" + val.toString() + "]",
      hazardAssessmentId: hazardId.toString(),
      token: token,
    }).toString();

    axios
      .post("/hazardAssessment/attachControls", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? onAttachControls(response.data.reply.success)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Attach Controls: " + error));
  };

  const onAttachRisks = (response, label, riskCont) => {
    let riskListArray = riskList.slice();
    let riskDeleteArray = riskDeleteIds.slice();
    let riskControlsArray = riskControls.slice();

    // set risks and riskDeleteIds
    for (let i = 0; i < response.length; i++) {
      let taskId = response[i]["task"]["id"];
      let riskId = response[i]["risk"]["id"];
      let riskName = label;
      let deleteId = response[i]["id"];
      let residual = response[i]["residual"];

      // update riskControls to include any associated with new risk

      for (let k = 0; k < riskCont.length; k++) {
        let target = riskCont[k]["control"]["id"];
        let rId = riskCont[k]["risk"]["id"];
        riskControlsArray.push({
          controlId: target,
          riskId: rId,
          taskId: taskId,
        });
      }
      // update riskList
      if (riskListArray[taskId] === undefined) {
        riskListArray[taskId] = [];
      }

      if (riskListArray[taskId].find((r) => r.id === riskId)) {
        riskListArray[taskId] = riskListArray[taskId].filter(
          (r) => r.id !== riskId
        );
      }

      riskListArray[taskId].push({
        id: riskId,
        label: riskName,
        residual: residual,
      });
      // update risk delete Ids
      riskDeleteArray.push({
        riskId: riskId,
        taskId: taskId,
        deleteId: deleteId,
      });
    }
    setRiskControls(riskControlsArray);
    setRiskList(riskListArray);
    setRiskDeleteIds(riskDeleteArray);
  };

  const attachRisks = async (val, label) => {
    let token = await AsyncStorage.getItem("accessToken");
    let riskId = val;
    let risk = {
      riskId: riskId,
      probability: probabilityList[taskIndex][riskId],
      severity: severityList[taskIndex][riskId],
      taskId: taskIndex,
    };

    // build risk string
    let riskStr = "[{";
    for (let p in risk) {
      riskStr += p + ":" + risk[p] + ",";
    }
    riskStr = riskStr.slice(0, -1);
    riskStr += "}]";

    let encodedStr = new URLSearchParams({
      hazardAssessmentId: hazardId.toString(),
      risks: riskStr,
      token: token,
    }).toString();

    axios
      .post("/hazardAssessment/attachRisks", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? onAttachRisks(
              response.data.reply.success,
              label,
              response.data.reply.riskControls[0]
            )
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Attach Risks: " + error.response.data));
  };

  const onDetachTasks = (deleteId, taskId) => {
    let taskDeleteArray = taskDeleteIds;
    taskDeleteArray = taskDeleteArray.filter((d) => d["deleteId"] !== deleteId);
    setTaskDeleteIds(taskDeleteArray);

    // clear risks
    let currentRisks: any = riskList.slice()[taskId];

    if (currentRisks !== undefined && currentRisks.length > 0) {
      currentRisks = currentRisks.map((r) => r.id);

      // remove associated control deleteIDs

      let targets = riskControls
        .slice()
        .filter(
          (r) => r["taskId"] === taskId && currentRisks.includes(r["riskId"])
        )
        .map((r) => r["controlId"]);

      // update risk controls
      let riskControlsArray = riskControls.slice();

      for (let i = 0; i < targets.length; i++) {
        riskControlsArray = riskControlsArray.filter(
          (rc) => !(rc["controlId"] === targets[i] && rc["taskId"] === taskId)
        );
      }

      let riskControlsIds = riskControlsArray.map((r) => r["controlId"]);

      let controlDeleteArray = controlDeleteIds.slice();

      controlDeleteArray = controlDeleteArray.filter((c) =>
        riskControlsIds.includes(c["controlId"])
      );

      // update controls
      let controlsArray = controls.slice();

      controlsArray = controlsArray.filter((c) =>
        riskControlsIds.includes(c.id)
      );

      let riskDeleteArray = riskDeleteIds.slice();

      for (let i = 0; i < currentRisks.length; i++) {
        // update risk delete Ids
        riskDeleteArray = riskDeleteArray.filter(
          (r) => !(r["riskId"] === currentRisks[i] && r["taskId"] === taskId)
        );
        // call risk detach for each attached risk
        detachRisks(currentRisks[i], taskId);
      }

      setRiskDeleteIds(riskDeleteArray);
      setControlDeleteIds(controlDeleteArray);
      setRiskControls(riskControlsArray);
      setControls(controlsArray);
    }
  };

  const detachTasks = async (val) => {
    let token = await AsyncStorage.getItem("accessToken");

    // get target task to delete
    let toDelete = taskDeleteIds?.find((d) => d["taskId"] === val)["deleteId"];

    let encodedStr = new URLSearchParams({
      removeHazardTaskIds: "[" + toDelete.toString() + "]",
      hazardAssessmentId: hazardId.toString(),
      token: token,
    }).toString();

    axios
      .post("/hazardAssessment/detachTasks", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? onDetachTasks(toDelete, val)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Remove Tasks: " + error));
  };

  const onDetachControls = (response, deleteId, rListArray?: Risk[][]) => {
    let controlDeleteArray;

    // update delete IDs
    controlDeleteArray = controlDeleteIds;

    controlDeleteArray = controlDeleteArray.filter(
      (r) => r["deleteId"] !== deleteId
    );

    if (prompt === "Controls") {
      setControlDeleteIds(controlDeleteArray);
    }

    // update risk list
    let riskListArray;

    if (rListArray !== undefined) {
      riskListArray = rListArray;
    } else {
      riskListArray = riskList.slice();
    }

    for (let i = 0; i < response["length"]; i++) {
      let riskId = response[i]["risk"]["id"];
      let taskId = response[i]["task"]["id"];
      let residual = response[i]["residual"];
      riskListArray[taskId].find((r) => r.id === riskId).residual = residual;
    }
    setRiskList(riskListArray);
  };

  const detachControls = async (val, riskListArray?: Risk[][]) => {
    let token = await AsyncStorage.getItem("accessToken");

    // find target control to delete
    let toDelete = controlDeleteIds
      .slice()
      ?.find((d) => d["controlId"] === val)["deleteId"];

    let encodedStr = new URLSearchParams({
      removeHazardControlIds: "[" + toDelete.toString() + "]",
      hazardAssessmentId: hazardId.toString(),
      token: token,
    }).toString();

    axios
      .post("/hazardAssessment/detachControls", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? onDetachControls(
              response.data.reply.success,
              toDelete,
              riskListArray
            )
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Remove Controls: " + error));
  };

  const onDetachRisks = async (riskId, deleteId, tId?: number) => {
    let taskId;
    if (prompt === "Risks") {
      taskId = taskIndex;
      // update risk delete Ids
      let riskDeleteArray = riskDeleteIds;
      riskDeleteArray = riskDeleteArray.filter(
        (r) => r["deleteId"] !== deleteId
      );
      setRiskDeleteIds(riskDeleteArray);
    } else if (prompt === "Tasks") {
      taskId = tId;
    }
    // update risk list
    let riskListArray = riskList.slice();
    riskListArray[taskId] = riskListArray[taskId].filter(
      (r) => r.id !== riskId
    );
    setRiskList(riskListArray);

    //detach any associated controls, and update riskControls
    let riskControlsArray = riskControls.slice();
    riskControlsArray = riskControlsArray.filter(
      (rc) => !(rc["riskId"] === riskId && rc["taskId"] === taskId)
    );

    if (prompt !== "Tasks") {
      setRiskControls(riskControlsArray);
    }

    // update riskControls

    let rcIds = riskControlsArray.map((r) => r["controlId"]);

    let toDetach: any;
    toDetach = riskControls.filter(
      (r) => r["riskId"] === riskId && r["taskId"] === taskId
    );
    toDetach = [...new Set(toDetach.map((o) => o["controlId"]))];

    toDetach = toDetach.filter((d) => !rcIds.includes(d));

    if (toDetach.length > 0) {
      // detach controls

      let targets: any = controls.filter((c) => toDetach.includes(c["id"]));

      targets = targets.map((t) => t["id"]);

      let controlDeleteArray = controlDeleteIds.slice();

      for (let i = 0; i < targets.length; i++) {
        controlDeleteArray = controlDeleteArray.filter(
          (c) => c["controlId"] !== targets[i]
        );
        detachControls(targets[i], riskListArray);
      }

      if (prompt !== "Tasks") {
        setControlDeleteIds(controlDeleteArray);
        setControls(controls.filter((c) => !toDetach.includes(c["id"])));
      }
    }
  };

  const detachRisks = async (val, taskId?: number) => {
    let token = await AsyncStorage.getItem("accessToken");

    let toDelete;

    // get target risk to delete

    if (prompt === "Risks") {
      toDelete = riskDeleteIds?.find(
        (d) => d["riskId"] === val && d["taskId"] === taskIndex
      )["deleteId"];
    } else if (prompt === "Tasks") {
      toDelete = riskDeleteIds?.find(
        (d) => d["riskId"] === val && d["taskId"] === taskId
      )["deleteId"];
    }

    let encodedStr = new URLSearchParams({
      removeHazardRiskIds: "[" + toDelete.toString() + "]",
      token: token,
    }).toString();

    axios
      .post("/hazardAssessment/detachRisks", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? prompt === "Risks"
            ? onDetachRisks(val, toDelete)
            : onDetachRisks(val, toDelete, taskId)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Remove Risks: " + error));
  };

  // function called when selected value in dropdown changes
  const handleSelection = (val: number | null) => {
    let label = "";
    setSelection(val);
    if (Platform.OS !== "ios") {
      if (val != null) {
        // update chip array
        chipArray.push(
          optionsArray.filter((obj) => obj.id === parseInt(val.toString()))[0]
            .id
        );
        setChipArray(chipArray);
        // update data list
        if (prompt !== "Risks") {
          setDataList(defaultArray.filter((obj) => chipArray.includes(obj.id)));
        }
      }
      if (prompt === "Risks") {
        let riskListCopy = riskList.slice();
        riskListCopy[taskIndex] = defaultArray.filter((obj) =>
          chipArray.includes(obj.id)
        );
        label = riskListCopy[taskIndex].find(
          (obj) => obj.id === parseInt(val.toString())
        ).label;
      }

      // update options
      setOptionsArray(optionsArray.filter((obj) => obj.id !== val));
      // return to default selection
      setSelection(null);

      // auto save
      switch (prompt) {
        case "Tasks":
          attachTasks(val);
          break;
        case "Controls":
          attachControls(val);
          break;
        case "Risks":
          attachRisks(val, label);
          break;
        default:
          break;
      }
    }
  };

  // function called when a chip is removed
  const handleClose = async (chip: number) => {
    // update chip array
    let updatedArray: number[] = chipArray.filter((c) => c !== chip);
    setChipArray(updatedArray);

    if (prompt !== "Risks") {
      // update data list
      setDataList(defaultArray.filter((obj) => updatedArray.includes(obj.id)));
    }

    // if tasks, clear risks
    if (prompt === "Tasks") {
      let riskListArray = riskList.slice();
      riskListArray[chip] = [];
      setRiskList(riskListArray);
    }
    // update options
    setOptionsArray(
      optionsArray.concat(defaultArray?.filter((obj) => obj.id === chip)[0])
    );
    // auto save
    switch (prompt) {
      case "Tasks":
        detachTasks(chip);
        break;
      case "Controls":
        detachControls(chip);
        break;
      case "Risks":
        detachRisks(chip);
        break;
      default:
        break;
    }
    // return selection to default
    setSelection(null);
  };

  // function called when selection is submitted on iOS
  const handleAddIOS = () => {
    let label = "";
    if (selection !== null) {
      // update chip array
      chipArray.push(optionsArray.filter((obj) => obj.id === selection)[0].id);
      setChipArray(chipArray);
      if (prompt !== "Risks") {
        // update data list
        setDataList(defaultArray.filter((obj) => chipArray.includes(obj.id)));
      }

      let riskListCopy = riskList;
      riskListCopy[taskIndex] = defaultArray.filter((obj) =>
        chipArray.includes(obj.id)
      );
      label = riskListCopy[taskIndex].find((obj) => obj.id === selection).label;

      // auto save
      switch (prompt) {
        case "Tasks":
          attachTasks(selection);
          break;
        case "Controls":
          attachControls(selection);
          break;
        case "Risks":
          attachRisks(selection, label);
          break;
        default:
          break;
      }
      // update options
      setOptionsArray(optionsArray.filter((obj) => obj.id !== selection));
      // return selection to default
      setSelection(null);
    }
  };

  return (
    <View
      style={[
        styles.pickerView,
        {
          width: prompt === "Risks" ? "100%" : "80%",
          marginBottom: prompt === "Risks" && chipArray.length === 0 ? 20 : 0,
          overflow:
            Platform.OS === "web" && prompt === "Controls" ? "scroll" : null,
        },
      ]}
    >
      {Platform.OS === "ios" ? (
        <View style={styles.buttonContainer}>
          <Button
            mode="contained"
            style={styles.button}
            onPress={() => handlePress()}
          >
            {toggle ? "Close" : "Add"}
          </Button>
        </View>
      ) : (
        <></>
      )}
      {toggle || Platform.OS !== "ios" ? (
        <>
          <Picker
            prompt={prompt}
            style={styles.picker}
            selectedValue={selection}
            onValueChange={(val: number | null) => handleSelection(val)}
          >
            <Picker.Item label="Select..." value={null} />
            {optionsArray?.length > 0 ? (
              optionsArray.map((option) => (
                <Picker.Item
                  key={option.id}
                  label={option.label}
                  value={option.id}
                />
              ))
            ) : (
              <></>
            )}
          </Picker>
          {Platform.OS === "ios" ? (
            <View style={styles.buttonContainer}>
              <Button
                mode="contained"
                style={[styles.button]}
                onPress={() => handleAddIOS()}
              >
                {" "}
                Submit{" "}
              </Button>
            </View>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {chipArray.map((chip) => (
        <Chip style={styles.chip} key={chip} onClose={() => handleClose(chip)}>
          {defaultArray?.filter((obj) => obj.id === chip)[0]?.label}
        </Chip>
      ))}
    </View>
  );
};

export default MultiPicker;

const styles = StyleSheet.create({
  pickerView: {
    flex: Platform.OS !== "web" ? 1 : null,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f8f9fa",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 3,
    marginTop: 15,
    padding: 5,
    paddingTop: Platform.OS !== "web" ? 0 : 5,
  },
  picker: {
    width: "100%",
    padding: 20,
  },
  button: {
    marginTop: 5,
  },
  chip: {
    marginLeft: 5,
    marginTop: 5,
  },
  buttonContainer: {
    width: "100%",
  },
});
