import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

// customAxios: Handles all API calls in the app

// create new axios instance
const customAxios = axios.create({});

// on request
const requestHandler = (request) => {
  (request) => console.log("Starting Request", JSON.stringify(request));
  async (config) => {
    // set access token
    const accessToken = await AsyncStorage.getItem("accessToken");
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  };
  return request;
};

// on response
const responseHandler = (response) => {
  async (config) => {
    if (response.status === 401) {
      // clear local storage
      await AsyncStorage.setItem("accessToken", "");
      await AsyncStorage.setItem("userId", "");
    }
    return config;
  };
  return response;
};

// on error
const errorHandler = (error) => {
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  async (config) => {
    // set baseURL
    let sub = await AsyncStorage.getItem("subdomain");
    let root = await AsyncStorage.getItem("rootDomain");

    config.baseURL = "https://" + sub + root;
    return config;
  },
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
