import React, { useEffect, useState, useContext } from "react";
import { StyleSheet, View, ScrollView, Alert, Platform } from "react-native";
import DismissKeyboard from "../Components/DismissKeyboard";
import MultiPicker from "../Components/MultiPicker";
import { Text, Button, Title, List } from "react-native-paper";
import RiskDisplay from "../Components/RiskDisplay";
import KeyValuePair from "../Types/KeyValuePair";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import RootStackParamList from "../Types/RootStackParamList";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "../Services/customAxios";
import Risk from "../Types/Risk";
import theme from "../theme";

// set current screen
type ScreenProps = NativeStackScreenProps<RootStackParamList, "Hazard">;

// HazardAssessment: component/screen for hazardAssessment creation/updating

const HazardAssessment = ({ navigation, route }: ScreenProps) => {
  // hazard assessment id
  let { hazardId, source } = route.params;
  // context
  const [riskList, setRiskList] = useState<Risk[][]>([[]]);
  // field data on page load
  const [initialData, setInitialData] = useState({});
  // task data for picker
  const [taskData, setTaskData] = useState([]);
  // controls data for picker
  const [controlsData, setControlsData] = useState([]);
  // risk data for pickers
  const [riskDataList, setRiskDataList] = useState<KeyValuePair[][]>([[]]);
  // stores risk selections for all tasks
  // store severity all risks
  const [severityList, setSeverityList] = useState<number[][]>([[]]);
  // store probability for all risks
  const [probabilityList, setProbabilityList] = useState<number[][]>([[]]);
  // special ids to be sent with delete
  const [taskDeleteIds, setTaskDeleteIds] = useState<object[]>([]);
  const [controlDeleteIds, setControlDeleteIds] = useState<object[]>([]);
  const [riskDeleteIds, setRiskDeleteIds] = useState<object[]>([]);
  const [tolerance, setTolerance] = useState<number | null>(null);

  // check for accessToken
  const getToken = async () => {
    const token = await AsyncStorage.getItem("accessToken");
    if (!token) {
      navigation.replace("Login");
    }
  };

  // headers to be sent with axios calls
  const headers =
    Platform.OS !== "web"
      ? {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache",
        }
      : { "Content-Type": "application/x-www-form-urlencoded" };

  // set defaults from save
  const setDefaults = (response: object) => {
    //set tolerance
    setTolerance(response["hazardAssessments"][0]["tolerance"]);
    // set initial data
    setInitialData(response);
    // set job
    setJobSelection(response["hazardAssessments"][0]["jobId"]);
    // set site
    setSiteSelection(response["hazardAssessments"][0]["siteId"]);
    // set taskCategory
    setTaskCategorySelection(
      response["hazardAssessments"][0]["taskCategoryId"]
    );
    // set tasks and taskDeleteIds
    let taskArray: KeyValuePair[] = [];
    let taskDeleteArray = [];
    for (let i = 0; i < response["tasks"].length; i++) {
      taskArray.push({
        id: response["tasks"][i]["taskId"],
        label: response["tasks"][i]["taskName"],
      });
      taskDeleteArray.push({
        taskId: response["tasks"][i]["taskId"],
        deleteId: response["tasks"][i]["hazardTaskAssessmentId"],
      });
    }
    setTaskDeleteIds(taskDeleteArray);
    // set controls and controlDeleteIds
    let controlArray: KeyValuePair[] = [];
    let controlDeleteArray = [];
    for (let i = 0; i < response["controls"].length; i++) {
      controlArray.push({
        id: response["controls"][i]["controlId"],
        label: response["controls"][i]["controlName"],
      });
      controlDeleteArray.push({
        controlId: response["controls"][i]["controlId"],
        deleteId: response["controls"][i]["hazardControlId"],
      });
    }
    setControls(controlArray);
    setControlDeleteIds(controlDeleteArray);
    // set risks and riskDeleteIds
    let riskListArray: Risk[][] = riskList;
    let riskDeleteArray = riskDeleteIds;
    let riskControlsArray = [];

    for (let i = 0; i < response["risks"].length; i++) {
      let taskId = response["risks"][i]["taskId"];
      let riskId = response["risks"][i]["riskId"];
      let riskName = response["risks"][i]["riskName"];
      let deleteId = response["risks"][i]["hazardRiskAssessmentId"];
      let residual = response["risks"][i]["residual"];

      for (let k = 0; k < response["risks"][i]["riskControls"].length; k++) {
        let target = response["risks"][i]["riskControls"][k]["control"]["id"];
        riskControlsArray.push({
          controlId: target,
          riskId: riskId,
          taskId: taskId,
        });
      }

      if (riskListArray[taskId] === undefined) {
        riskListArray[taskId] = [];
      }
      // check for duplicate data (should be fixed in db)
      if (!riskListArray[taskId].find((r) => r.id === riskId)) {
        riskListArray[taskId].push({
          id: riskId,
          label: riskName,
          residual: residual,
        });
      }
      riskDeleteArray.push({
        riskId: riskId,
        taskId: taskId,
        deleteId: deleteId,
      });
    }

    setRiskControls(riskControlsArray);
    setRiskList(riskListArray);
    setRiskDeleteIds(riskDeleteArray);

    setTasks(taskArray);
  };
  // get data on page load
  const axiosCall = async () => {
    let token = await AsyncStorage.getItem("accessToken");

    // get current hazardAssessment
    axios
      .get(
        "/hazardAssessment/get?hazardAssessmentId=" +
          hazardId +
          "&token=" +
          token,
        { headers: headers }
      )
      .then((response) =>
        response.status === 200
          ? setDefaults(response.data.reply)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("HA:" + error));

    // format controls response
    const formatControls = (response) => {
      let controlArray: KeyValuePair[] = [];
      for (let i = 0; i < response.length; i++) {
        controlArray.push({
          id: response[i]["controlID"],
          label: response[i]["controlName"],
        });
      }
      setControlsData(controlArray);
    };

    // get controls
    axios
      .get("/control/getAll?token=" + token, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? formatControls(response.data.reply)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Controls: " + error.response?.data));
  };

  // run on initial render
  useEffect(() => {
    getToken();
    axiosCall();
  }, []);

  const taskCall = async () => {
    let token = await AsyncStorage.getItem("accessToken");
    // format task response
    const formatTasks = (response) => {
      let taskArray: KeyValuePair[] = [];
      for (let i = 0; i < response.length; i++) {
        taskArray.push({
          id: response[i]["id"],
          label: response[i]["taskName"],
        });
      }
      setTaskData(taskArray);
    };
    // get tasks by taskCategory
    axios
      .get(
        "/task/getTaskByTaskCategory?taskCategoryId=" +
          taskCategorySelection +
          "&isActive=true&token=" +
          token,
        {
          headers: headers,
        }
      )
      .then((response) =>
        response.status === 200
          ? formatTasks(response.data.reply)
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Tasks: " + error.response?.data));
  };

  // handles expansion of accordions
  const [expanded, setExpanded] = useState<number | null>(null);
  // id of selected job
  const [jobSelection, setJobSelection] = useState<number | null>(null);
  // id of selected site
  const [siteSelection, setSiteSelection] = useState<number | null>(null);
  // id of selected taskCategory
  const [taskCategorySelection, setTaskCategorySelection] = useState<
    number | null
  >(null);
  // list of selected tasks
  const [tasks, setTasks] = useState([]);
  // list of selectedRisks sent to multipicker to adhere to typescript rules (use riskList instead)
  const [risks, setRisks] = useState<KeyValuePair[]>([]);
  // current selected controls
  const [controls, setControls] = useState<KeyValuePair[]>([]);
  // current riskControls
  const [riskControls, setRiskControls] = useState<object[]>([]);

  // make taskCall on taskCategory change
  useEffect(() => {
    if (taskCategorySelection !== null) {
      taskCall();
    }
  }, [taskCategorySelection]);

  // called when task list is updated
  const riskCall = async () => {
    let token = await AsyncStorage.getItem("accessToken");

    // get array of ids from tasks
    let taskIds = tasks.map((t) => t.id);
    let targetTaskIds: number[] = [];

    // get target ids (not already fetched)
    for (let i = 0; i < taskIds.length; i++) {
      if (
        riskDataList[taskIds[i]] === undefined ||
        riskDataList[taskIds[i]].length === 0
      ) {
        targetTaskIds.push(taskIds[i]);
      }
    }
    // if no targets, return
    if (targetTaskIds.length === 0) {
      return;
    }

    // format data for hazardAssessment creation
    const buildRiskStr = (riskArray, taskIndex) => {
      let riskStrArray = [];

      for (let k = 0; k < riskArray.length; k++) {
        let riskId = riskArray[k].id;
        let risk = {
          riskId: riskArray[k].id,
          taskId: taskIndex,
          severity: severityList[taskIndex][riskId],
          probability: probabilityList[taskIndex][riskId],
        };
        riskStrArray.push(risk);
      }

      // build risk string
      let riskStr = "[";
      for (let i = 0; i < riskStrArray.length; i++) {
        let obj = riskStrArray[i];
        riskStr += "{";
        for (let p in obj) {
          riskStr += p + ":" + obj[p] + ",";
        }
        riskStr = riskStr.slice(0, -1);
        riskStr += "},";
      }
      riskStr = riskStr.slice(0, -1);
      riskStr += "]";

      return riskStr;
    };

    const autoFillControls = (response, controlIds) => {
      let controlsArray = controls.slice();
      let controlDeleteArray = controlDeleteIds.slice();

      // add new controls

      let newControls = controlsData.filter((c) => controlIds.includes(c.id));

      controlsArray = controlsArray.concat(newControls);

      // update control delete Ids
      for (let i = 0; i < response["hazardControls"].length; i++) {
        controlDeleteArray.push({
          controlId: response["hazardControls"][i]["control"]["id"],
          deleteId: response["hazardControls"][i]["id"],
        });
      }
      setControlDeleteIds(controlDeleteArray);
      // update riskList
      let riskListArray = riskList.slice();
      for (let i = 0; i < response["hazardRisks"].length; i++) {
        let riskId = response["hazardRisks"][i]["risk"]["id"];
        let taskId = response["hazardRisks"][i]["task"]["id"];
        let residual = response["hazardRisks"][i]["residual"];
        riskListArray[taskId].find((r) => r.id === riskId).residual = residual;
      }
      setRiskList(riskListArray);

      setControls(controlsArray);
    };

    const autoFillRisks = async (
      response,
      riskCont,
      convertedArray,
      targetTaskId
    ) => {
      let token = await AsyncStorage.getItem("accessToken");

      let riskDeleteArray = riskDeleteIds.slice();
      let riskControlsArray = [];

      // update risklist and delete Ids

      for (let i = 0; i < convertedArray.length; i++) {
        convertedArray[i].residual = response.find(
          (o) => o.risk.id === convertedArray[i].id
        )["residual"];

        riskDeleteArray.push({
          riskId: convertedArray[i].id,
          taskId: targetTaskId,
          deleteId: response.find((o) => o.risk.id === convertedArray[i].id)[
            "id"
          ],
        });
      }

      // update riskControls for new attached risks
      for (let k = 0; k < riskCont.length; k++) {
        for (let n = 0; n < riskCont[k].length; n++) {
          let target = riskCont[k][n]["control"]["id"];
          let rId = riskCont[k][n]["risk"]["id"];
          riskControlsArray.push({
            controlId: target,
            riskId: rId,
            taskId: targetTaskId,
          });
        }
      }

      let copyRiskList = riskList;
      copyRiskList[targetTaskId] = convertedArray;

      setRiskList(copyRiskList);

      setRiskControls(riskControls.slice().concat(riskControlsArray));

      setRiskDeleteIds(riskDeleteArray);

      // auto attach all controls

      let controlIds = riskControlsArray.map((r) => r["controlId"]);
      controlIds = controlIds.filter(
        (c) => !controls.map((c) => c.id).includes(c)
      );
      if (controlIds.length > 0) {
        let controlStr = "[";
        for (let i = 0; i < controlIds.length; i++) {
          controlStr = controlStr.concat(controlIds[i].toString() + ",");
        }
        controlStr = controlStr.slice(0, -1);
        controlStr = controlStr.concat("]");

        let encodedStr = new URLSearchParams({
          controlIds: controlStr,
          hazardAssessmentId: hazardId.toString(),
          token: token,
        }).toString();

        axios
          .post("/hazardAssessment/attachControls", encodedStr, {
            headers: headers,
          })
          .then((response) =>
            response.status === 200
              ? autoFillControls(response.data.reply.success, controlIds)
              : console.log("Issue with response data")
          )
          .catch((error) => console.warn("Attach Controls: " + error));
      }
    };

    // called on successful risk get
    const updateRiskArray = (response: object[], targetTaskId: number) => {
      let riskDataListArray = riskDataList;
      let severityListArray = severityList;
      let probabilityListArray = probabilityList;
      let riskListArray = riskList;

      let convertedArray: Risk[] = [];
      let severityListDefault: number[] = [];
      let probabilityListDefault: number[] = [];

      // get risk id in response
      let responseIds = response.map((r) => r["id"]);
      let maxId = Math.max(...responseIds);

      // prepopulate the severity/probability arrays up to the maxId
      for (let j = 0; j <= maxId; j++) {
        severityListDefault.push(0);
        probabilityListDefault.push(0);
      }
      for (let i = 0; i < response.length; i++) {
        // convert response to Risk type
        convertedArray.push({
          id: response[i]["id"],
          label: response[i]["riskName"],
        });
        // if initialData, get initial severity and probability
        if (Object.keys(initialData).length > 0) {
          let initialSeverity = initialData["risks"].find(
            (r) => r.taskId === targetTaskId && r.riskId === response[i]["id"]
          )?.severity;
          if (initialSeverity !== undefined) {
            severityListDefault[response[i]["id"]] = initialSeverity;
          } else {
            severityListDefault[response[i]["id"]] = response[i]["severity"];
          }
          let initialProbability = initialData["risks"].find(
            (r) => r.taskId === targetTaskId && r.riskId === response[i]["id"]
          )?.probability;
          if (initialProbability !== undefined) {
            probabilityListDefault[response[i]["id"]] = initialProbability;
          } else {
            probabilityListDefault[response[i]["id"]] =
              response[i]["probability"];
          }
        } else {
          severityListDefault[response[i]["id"]] = response[i]["severity"];
          probabilityListDefault[response[i]["id"]] =
            response[i]["probability"];
        }
      }
      // fill empties
      for (let k = 1; k <= targetTaskId; k++) {
        if (riskDataListArray[k] === undefined) {
          riskDataListArray[k] = [];
        }
      }
      for (let g = 0; g <= targetTaskId; g++) {
        if (riskListArray[g] === undefined) {
          riskListArray[g] = [];
        }
        if (
          severityListArray[g] === undefined ||
          severityListArray[g].length === 0
        ) {
          severityListArray[g] = severityListDefault;
        }
        if (
          probabilityListArray[g] === undefined ||
          probabilityListArray[g].length === 0
        ) {
          probabilityListArray[g] = probabilityListDefault;
        }
      }
      // push changes
      riskDataListArray[targetTaskId] = convertedArray;
      severityListArray[targetTaskId] = severityListDefault;
      probabilityListArray[targetTaskId] = probabilityListDefault;

      let initialTasks = initialData["tasks"].map((t) => t.taskId);

      // if no risks have already been selected for this task, autofill with all
      let currentContents = riskList.slice()[targetTaskId];
      if (
        !initialTasks.includes(targetTaskId) &&
        (currentContents === undefined || currentContents.length === 0)
      ) {
        if (convertedArray.length > 0) {
          let riskStr = buildRiskStr(convertedArray, targetTaskId);

          let encodedStr = new URLSearchParams({
            risks: riskStr,
            hazardAssessmentId: hazardId.toString(),
            token: token,
          }).toString();

          // attach risks
          axios
            .post("/hazardAssessment/attachRisks", encodedStr, {
              headers: headers,
            })
            .then((response) =>
              response.status === 200
                ? autoFillRisks(
                    response.data.reply.success,
                    response.data.reply.riskControls,
                    convertedArray,
                    targetTaskId
                  )
                : console.log("Issue with response data")
            )
            .catch((error) =>
              console.warn("Attach Default Risks: " + error.response?.data)
            );
        }
      }
      setRiskList(riskListArray);
      setRiskDataList(riskDataListArray);
      setSeverityList(severityListArray);
      setProbabilityList(probabilityListArray);
    };

    // get risks for target tasks
    for (let i = 0; i < targetTaskIds.length; i++) {
      axios
        .get(
          "/hazardAssessment/getRiskByTaskId?taskId=" +
            targetTaskIds[i] +
            "&token=" +
            token,
          {
            headers: headers,
          }
        )
        .then((response) =>
          response.status === 200
            ? updateRiskArray(response.data.reply, targetTaskIds[i])
            : console.log("Issue with response data")
        )
        .catch((error) => console.warn("Risks: " + error));
    }
  };

  // call for risks, on tasks update
  useEffect(() => {
    if (tasks.length > 0) {
      riskCall();
    }
  }, [tasks]);

  // validate whether form is able to be submitted
  const validateForm = () => {
    // if not empty
    if (tasks.length > 0) {
      // check if each task has at least one risk
      for (let i = 0; i < tasks.length; i++) {
        let taskId = tasks[i].id;

        if (riskList[taskId] === undefined || riskList[taskId].length === 0) {
          // throw validation error
          alert("All tasks require at least one risk.");
          return false;
        }
      }
    } else {
      // throw validation error
      alert("Please select at least one task.");
      return false;
    }
    return true;
  };

  // complete/sign assessment
  const handleCompleteAssessment = async () => {
    if (!validateForm()) {
      return;
    }

    let userId = await AsyncStorage.getItem("userId");
    let token = await AsyncStorage.getItem("accessToken");

    let sendEmail = false;

    // run through riskList to find any residual over tolerance
    for (let i = 0; i < riskList.length; i++) {
      if (riskList[i].find((r) => r.residual > tolerance) !== undefined) {
        sendEmail = true;
        break;
      }
    }

    const sendWebAlert = () => {
      window.alert(
        "The assessment was successfully completed." + sendEmail
          ? "A warning email has been sent."
          : ""
      );
      navigation.navigate("Home");
    };

    // format data
    let encodedStr = new URLSearchParams({
      userId: userId.toString(),
      hazardAssessmentId: hazardId.toString(),
      token: token,
      sendEmail: sendEmail.toString(),
    }).toString();

    axios
      .post("/hazardAssessment/complete", encodedStr, {
        headers: headers,
      })
      .then((response) =>
        response.status === 200
          ? Platform.OS !== "web"
            ? Alert.alert(
                "Success!",
                "The assessment was successfully completed." + sendEmail
                  ? "A warning email has been sent."
                  : "",
                [
                  {
                    text: "OK",
                    onPress: () => navigation.navigate("Home"),
                  },
                ]
              )
            : sendWebAlert()
          : console.log("Issue with response data")
      )
      .catch((error) => console.warn("Complete: " + error.response?.data));
  };

  // delete hazard assessment
  const handleDeleteAssessment = async () => {
    let token = await AsyncStorage.getItem("accessToken");

    const deleteCall = () => {
      // run delete call
      axios
        .get(
          "/hazardAssessment/delete?token=" +
            token +
            "&hazardAssessmentId=" +
            hazardId,
          {
            headers: headers,
          }
        )
        .then((response) =>
          response.status === 200
            ? navigation.navigate("Home")
            : console.log("Issue with response data")
        )
        .catch((error) => console.warn("Delete: " + error.response?.data));
    };
    // confirm delete
    if (Platform.OS !== "web") {
      Alert.alert(
        "Confirm Delete",
        "Are you sure you want to delete this draft?",
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          { text: "OK", onPress: () => deleteCall() },
        ]
      );
    } else {
      if (window.confirm("Are you sure you want to delete this draft?")) {
        deleteCall();
      }
    }
  };

  const component = (
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        <Title>Field Hazard Assessment</Title>
        {/* <Text> {hazardId} </Text>
            <Text> {tolerance} </Text> */}
        {/* Tasks */}
        {taskCategorySelection !== null ? (
          <>
            <Text style={styles.heading}> Tasks * </Text>
            <MultiPicker
              prompt="Tasks"
              dataSet={taskData}
              dataList={tasks}
              setDataList={setTasks}
              hazardId={hazardId}
              taskDeleteIds={taskDeleteIds}
              setTaskDeleteIds={setTaskDeleteIds}
              riskList={riskList}
              setRiskList={setRiskList}
              riskDeleteIds={riskDeleteIds}
              setRiskDeleteIds={setRiskDeleteIds}
              riskControls={riskControls}
              setRiskControls={setRiskControls}
              controls={controls}
              setControls={setControls}
              controlDeleteIds={controlDeleteIds}
              setControlDeleteIds={setControlDeleteIds}
            />
          </>
        ) : (
          <></>
        )}
        {/* Accordions */}
        <List.Section style={styles.accordion}>
          {tasks.length > 0 ? (
            tasks.map((task) => (
              <List.Accordion
                key={task.id}
                title={task.label}
                left={(props) => (
                  <List.Icon
                    {...props}
                    icon={
                      riskList[task.id]?.length > 0
                        ? riskList[task.id].find(
                            (r) => r.residual > tolerance
                          ) !== undefined
                          ? "alert-circle"
                          : "check-circle"
                        : "folder"
                    }
                    color={
                      riskList[task.id]?.length > 0
                        ? riskList[task.id].find(
                            (r) => r.residual > tolerance
                          ) !== undefined
                          ? theme.colors.error
                          : "green"
                        : theme.colors.primary
                    }
                  />
                )}
                expanded={expanded === task.id ? true : false}
                onPress={() =>
                  expanded !== task.id
                    ? setExpanded(task.id)
                    : setExpanded(null)
                }
              >
                {/* Risks */}
                <View style={styles.riskContainer}>
                  <Text style={styles.riskHeading}> Risks * </Text>
                </View>
                <MultiPicker
                  prompt="Risks"
                  dataSet={riskDataList[task.id]}
                  dataList={riskList[task.id]}
                  setDataList={setRisks}
                  taskIndex={task.id}
                  hazardId={hazardId}
                  riskDeleteIds={riskDeleteIds}
                  setRiskDeleteIds={setRiskDeleteIds}
                  severityList={severityList}
                  probabilityList={probabilityList}
                  riskControls={riskControls}
                  setRiskControls={setRiskControls}
                  controlDeleteIds={controlDeleteIds}
                  setControlDeleteIds={setControlDeleteIds}
                  controls={controls}
                  setControls={setControls}
                  riskList={riskList}
                  setRiskList={setRiskList}
                />
                {riskList[task.id] !== undefined ? (
                  riskList[task.id].map((risk) => (
                    <RiskDisplay
                      key={risk.id}
                      tolerance={tolerance}
                      riskName={risk.label}
                      taskIndex={task.id}
                      riskIndex={risk.id}
                      severityList={severityList}
                      setSeverityList={setSeverityList}
                      probabilityList={probabilityList}
                      setProbabilityList={setProbabilityList}
                      residual={risk.residual}
                      hazardId={hazardId}
                      riskDeleteIds={riskDeleteIds}
                      riskList={riskList}
                      setRiskList={setRiskList}
                    />
                  ))
                ) : (
                  <></>
                )}
              </List.Accordion>
            ))
          ) : (
            <></>
          )}
        </List.Section>
        {/* Controls */}
        {tasks.length > 0 ? (
          <>
            <Text style={styles.heading}> Controls </Text>
            <MultiPicker
              prompt="Controls"
              dataSet={controlsData.filter(
                (c) =>
                  riskControls.find((r) => r["controlId"] === c.id) != undefined
              )}
              dataList={controls}
              setDataList={setControls}
              hazardId={hazardId}
              controlDeleteIds={controlDeleteIds}
              setControlDeleteIds={setControlDeleteIds}
              riskList={riskList}
              setRiskList={setRiskList}
            />
          </>
        ) : (
          <></>
        )}
        <Button
          icon="content-save"
          mode="contained"
          style={{ marginTop: 20 }}
          onPress={() => navigation.navigate("Listings")}
        >
          Save and Close
        </Button>
        <Button
          icon="trash-can"
          mode="contained"
          style={{ marginTop: 20 }}
          onPress={handleDeleteAssessment}
        >
          Delete
        </Button>
        <Button
          icon="clipboard-check-outline"
          mode="contained"
          style={{ marginTop: 20 }}
          onPress={handleCompleteAssessment}
        >
          Sign Off
        </Button>
      </View>
    </ScrollView>
  );

  return Platform.OS !== "web" ? (
    <>
      <DismissKeyboard>{component}</DismissKeyboard>
    </>
  ) : (
    <> {component} </>
  );
};

export default HazardAssessment;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    padding: 30,
  },
  textInput: {
    width: "80%",
    marginTop: 20,
  },
  heading: {
    paddingTop: 15,
    fontSize: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
  },
  riskHeading: {
    fontSize: 16,
    paddingTop: 15,
  },
  accordion: {
    width: "80%",
    flex: 1,
    textAlign: "center",
    marginTop: 20,
  },
  picker: {
    width: "100%",
    padding: 20,
  },
  pickerView: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    backgroundColor: "#f8f9fa",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 3,
    marginTop: 15,
    padding: 5,
    paddingTop: 0,
  },
  riskContainer: {
    flex: 1,
    paddingLeft: 0,
    alignItems: "center",
  },
  buttonContainer: {
    width: "100%",
  },
  button: {
    marginTop: 5,
  },
  textRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%",
  },
  scrollView: {
    backgroundColor: "white",
  },
});
