import { View, StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import RootStackParamList from "../Types/RootStackParamList";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import AsyncStorage from "@react-native-async-storage/async-storage";

// set current page
type ScreenProps = NativeStackScreenProps<RootStackParamList, "Settings">;

// Settings: page for logout and other potential functions

const Settings = ({ navigation }: ScreenProps) => {
  // clear localStorage and navigate back to login
  const handleLogout = async () => {
    await AsyncStorage.setItem("accessToken", "");
    await AsyncStorage.setItem("userId", "");
    navigation.replace("Login");
  };

  return (
    <View style={styles.container}>
      <Button
        mode="contained"
        icon="logout"
        style={styles.button}
        onPress={handleLogout}
      >
        Logout
      </Button>
    </View>
  );
};

export default Settings;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  button: {
    marginTop: 20,
  },
});
